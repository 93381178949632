.ContainerBase{
  justify-content: center;
  z-index: 1;
}
.ScrollDark {
  height: 80px;
  top: 0px;
  position: fixed;
  transition: all ease-in-out 0.6s;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 3.5px 5.5px rgb(0 0 0 / 2%);
  backdrop-filter: saturate(180%) blur(50px);
}

.ScrollLight {
  top: 0px;
  position: fixed;
  height: 80px;
  transition: all ease-in-out 0.6s;
  
}
.FlexHeader {
  padding-bottom: 0px;
  color: #fff;
  align-items: center;
}

.FlexHeader .Box {
  flex: 1;
}

.MainHeading {
  color: #243e63;
  margin-bottom: 10px;
  line-height: 1;
}

.SubText {
  color: #ff5722;
}

.TextDescription {
  margin-bottom: 15px;
  max-width: 550px;
  font-size: 1.2rem;
  width: 100%;
  font-weight: 400;
  line-height: 1.4;
}

.HeaderInputGroup{
  box-shadow: rgb(140 152 164 / 25%) 0px 3px 6px 0px;
  background-color: #fff;
  border-radius: 7px;
  overflow: hidden;
}

.HeaderInputGroupInput{
  border-radius: none;
  padding-left: 15px;
  height: 50px;
  color: #718096;
}

.HeaderBg {
  background-image: url("../public/img/wave.webp");
  background-repeat: no-repeat;
  width: 100%;
  background-position: top center;
  background-size: 100%;
}

.BgImage {
  background-image: url("../public/img/platform-bg.webp");
  background-repeat: no-repeat;
  width: 100%;
  background-position: top center;
  background-size: 100%;
}

.BgWrap {
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -1;
}

.HeroDot {
  position: absolute;
  animation: rotate 5s infinite alternate;
}

.HomeAboutDis {
  max-width: 1030px;
  margin: auto;
  text-align: center;
  font-size: 1.25rem;
  width: 100%;
  font-weight: 400;
  line-height: 1.6;
}

.AboutCardBox {
  padding-bottom: 60px;
  margin: auto;
}

.AboutCard {
  padding: 40px;
  box-shadow: rgb(140 152 164 / 25%) 0px 3px 6px 0px;
  min-height: 83px;
  transition: 0.5s;
  overflow: hidden;
  transform: scale(1);
}

.AboutCard:hover {
  transform: scale(1.02);
}
.CardStatNumber {
  font-size: 1rem;
  font-weight: 400;
  color: #5c94d3;
  line-height: 1.5;
}
.CardStatLabel {
  padding-left: 30px;
}
.CardPoints {
  line-height: 36px;
}
.CardPointIcons {
  width: 20px;
  margin: 8px;
}
.CardPointText {
  font-weight: normal;
  font-size: medium;
}

.PlatformSectionBoxHeading {
  font-weight: 400;
  font-size: 1.15rem;
  color: #243e63;
  margin-bottom: 4rem;
  text-align: center;
}

.PlatformAccordionItem {
  background-color: #fff;
  margin-bottom: 1.2rem;
  border-radius: 7px;
  padding: 10px;
  box-shadow: rgb(140 152 164 / 25%) 0px 3px 6px 0px;
  border: none;
}

.PlatformAccordionIcon {
  font-size: 40px;
  color: #243e63;
}

.MenuListBox {
  border: 1px solid rgba(0, 0, 0, 0.12);
  max-width: 960px;
  height: 330px;
  overflow-y: auto;
  box-shadow: rgb(140 152 164 / 25%) 0px 3px 6px 0px;

}

.AllCategoriesWrap .MenuButtonBox {
  text-align:left;
  border: 1px solid #ddd;
  background: transparent !important;
  font-size: 14px;
  font-weight: 400;
}

.AllCategoriesTitle {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-weight: 600;
}
.AllCategoriesItemname{
  font-weight:500;
}
.AllCategoriesItemname:hover{
    color:#000821;
    transition:all 0.1s ease-in-out;
}
.LinkStyle{
  color:#039be5 !important;
  font-size: 18px;
  cursor: pointer;
}
